import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    id: String
  }

  connect() {
    if (!window.ybug_settings) {
      window.ybug_settings = { id: this.idValue }
      
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `https://widget.ybug.io/button/${this.idValue}.js`
      
      document.head.appendChild(script)
    }
  }
} 